import { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import LoadingSpinner from '@/components/ui/loding-spinner';
import { ProtectedRoute } from '@/features/auth/components/ProtectedRoute';
import Layout from '@/layouts/Layout';
import SidebarLayout from '@/layouts/SidebarLayout';
import ErrorPage from '@/pages/ErrorPage';
import BadRequest from '@/pages/ErrorPage/BadRequest';
import InternalServerError from '@/pages/ErrorPage/InternalServerError';
import Unauthorized from '@/pages/ErrorPage/Unauthorized';

const Home = lazy(() => import('@/pages/Home'));
const Login = lazy(() => import('@/features/auth/components/Login'));
const Signup = lazy(() => import('@/features/auth/components/Signup'));
const Pricing = lazy(() => import('@/pages/Pricing'));
const Settings = lazy(() => import('@/pages/Settings'));
const Workspace = lazy(() => import('@/pages/Workspace'));
const GettingStarted = lazy(() => import('@/pages/GettingStarted'));
const VerifyEmail = lazy(
  () => import('@/features/auth/components/VerifyEmail'),
);
const OAuthCallback = lazy(
  () => import('@/features/auth/components/OAuthCallback'),
);

// Footer Legal
const Terms = lazy(() => import('@/pages/Terms'));
const Privacy = lazy(() => import('@/pages/Privacy'));

const LazyLoad = ({ children }: { children: React.ReactNode }) => (
  <Suspense
    fallback={
      <div className="flex h-screen w-full items-center justify-center">
        <LoadingSpinner />
      </div>
    }
  >
    {children}
  </Suspense>
);

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <LazyLoad>
            <Home />
          </LazyLoad>
        ),
      },
      {
        path: 'login',
        element: (
          <LazyLoad>
            <Login />
          </LazyLoad>
        ),
      },
      {
        path: 'signup',
        element: (
          <LazyLoad>
            <Signup />
          </LazyLoad>
        ),
      },
      {
        path: 'oauth/callback',
        element: (
          <LazyLoad>
            <OAuthCallback />
          </LazyLoad>
        ),
      },
      {
        path: 'signup/verify-email',
        element: (
          <LazyLoad>
            <VerifyEmail />
          </LazyLoad>
        ),
      },
      { path: 'pricing', element: <Pricing /> },
      {
        path: 'get-started',
        element: (
          <LazyLoad>
            <GettingStarted />
          </LazyLoad>
        ),
      },
      {
        path: 'terms',
        element: (
          <LazyLoad>
            <Terms />
          </LazyLoad>
        ),
      },
      {
        path: 'privacy',
        element: (
          <LazyLoad>
            <Privacy />
          </LazyLoad>
        ),
      },
    ],
  },
  {
    element: <ProtectedRoute />,
    path: '/dashboard',
    children: [
      {
        path: 'workspace',
        element: <SidebarLayout />,
        children: [
          {
            index: true,
            element: (
              <LazyLoad>
                <Workspace />
              </LazyLoad>
            ),
          },
          {
            path: 'instance',
            element: (
              <LazyLoad>
                <Workspace defaultTab="instance" />
              </LazyLoad>
            ),
          },
          {
            path: 'network',
            element: (
              <LazyLoad>
                <Workspace defaultTab="network" />
              </LazyLoad>
            ),
          },
        ],
      },
    ],
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: '/settings',
        element: <SidebarLayout />,
        children: [
          {
            index: true,
            element: (
              <LazyLoad>
                <Settings />
              </LazyLoad>
            ),
          },
          {
            path: 'profile',
            element: (
              <LazyLoad>
                <Settings defaultTab="profile" />
              </LazyLoad>
            ),
          },
          {
            path: 'crossaccount',
            element: (
              <LazyLoad>
                <Settings defaultTab="crossaccount" />
              </LazyLoad>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/',
    errorElement: <ErrorPage />,
    children: [
      {
        path: '*',
        element: <ErrorPage />,
      },
      {
        path: 'error/400',
        element: <BadRequest />,
      },
      {
        path: 'error/401',
        element: <Unauthorized />,
      },
      {
        path: 'error/500',
        element: <InternalServerError />,
      },
      {
        path: 'error',
        element: <ErrorPage />,
      },
    ],
  },
]);
