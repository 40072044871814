import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store';
import { AuthActions } from '../store/auth.actions';
import { setAuthState, resetAuth } from '../store/auth.slice';
import { LoginPayload } from '../store/auth.types';

export const useLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const auth = useAppSelector((state) => state.auth);
  const isAuthenticated = useAppSelector(
    (state) =>
      state.auth.tokenType !== null && state.auth.tokenType === 'client_token',
  );

  // 로그인
  const login = async (payload: LoginPayload) => {
    const loginResponse = await AuthActions.login(payload);
    const { data: tokenType } = await AuthActions.getTokenType();

    // 리덕스 스토어에 로그인 정보 저장
    dispatch(
      setAuthState({
        tokenType,
        email: loginResponse.data.email,
      }),
    );

    queryClient.invalidateQueries(['user']);

    // 로그인 후 페이지 이동
    if (tokenType === 'guest_token') {
      navigate('/signup/verify-email', {
        replace: true,
        state: { email: loginResponse.data.email },
      });
    } else if (tokenType === 'client_token') {
      navigate('/dashboard/workspace', { replace: true });
    }
  };

  // 로그아웃
  const logout = async () => {
    try {
      await AuthActions.logout();
      dispatch(resetAuth());
      localStorage.clear();
      navigate('/', { replace: true });
      window.location.reload();
    } catch (error) {
      console.log('Failed to logout', error);
    }
  };

  return {
    ...auth,
    isAuthenticated,
    login,
    logout,
  };
};
