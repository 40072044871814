import { TypeOfTokenResponse } from '@circlesync/shared';
import { client } from '@/api/api.client';
import {
  SignupPayload,
  VerifyEmailPayload,
} from '@/features/auth/store/auth.types';

// 회원가입
export function signup(payload: SignupPayload): Promise<ApiResponse<string>> {
  return client
    .post('/api/v1/user/signup', payload)
    .then((response) => response.data);
}

// 쿠키 내 JWT 토큰 종류 확인
export function getTokenType(): Promise<ApiResponse<TypeOfTokenResponse>> {
  return client
    .post('/api/v1/user/token-type')
    .then((response) => response.data);
}

// 토큰 갱신
export function refreshToken(): Promise<ApiResponse<string>> {
  return client.post('/api/v1/user/refresh').then((response) => response.data);
}

// 이메일 인증
export async function verifyEmail(
  payload: VerifyEmailPayload,
): Promise<ApiResponse<string>> {
  return await client
    .post('/api/v1/user/email/verification', payload)
    .then((response) => response.data);
}

// 이메일 인증 코드 재전송
export async function resendEmailVerification(): Promise<ApiResponse<string>> {
  return await client
    .put('/api/v1/user/email')
    .then((response) => response.data);
}
