import { useState, useEffect } from 'react';
import {
  Box,
  ChevronDown,
  LogOut,
  Settings as SettingsIcon,
  User,
  Network,
  Server,
} from 'lucide-react';
import { Link } from 'react-router-dom';
import Logo from '@/assets/logo.svg';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import { Progress } from '@/components/ui/progress';
import { useLogin } from '@/features/auth/hooks/useLogin';
import { useUser } from '@/hooks/useUser';
import { useAppSelector } from '@/store';

export default function Sidebar() {
  const tasks = useAppSelector((state) => state.task.tasks);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isWorkspaceOpen, setIsWorkspaceOpen] = useState(true);

  const { logout } = useLogin();
  const { user, isLoading } = useUser();

  const [showGetStarted, setShowGetStarted] = useState(() => {
    const storageKey = `showGetStarted_${user.email}`;
    const saved = localStorage.getItem(storageKey);
    return saved !== null ? JSON.parse(saved) : true;
  });

  // 프로필 팝업 외부 클릭시 닫기
  const handleOutsideClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    if (!target.closest('.menu-container')) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    const storageKey = `showGetStarted_${user.email}`;
    localStorage.setItem(storageKey, JSON.stringify(showGetStarted));
  }, [showGetStarted, user.email]);

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleLogout = async () => {
    try {
      // 현재 사용자의 showGetStarted 상태만 유지
      const currentShowGetStarted = localStorage.getItem(
        `showGetStarted_${user.email}`,
      );

      await logout();

      // 로그아웃 후 다시 저장
      if (currentShowGetStarted) {
        localStorage.setItem(
          `showGetStarted_${user.email}`,
          currentShowGetStarted,
        );
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="fixed left-0 top-0 flex h-screen w-[270px] flex-col bg-sky-100/80 shadow-[2px_0_8px_rgba(0,0,0,0.1)]">
      {/* 로고 블럭 */}
      <div className="p-4">
        <div className="flex items-center gap-4">
          <Link to="/">
            <img
              src={Logo}
              alt="CircleSync Logo"
              className="h-10" // 로고 크기는 필요에 따라 조절하세요
            />
          </Link>
        </div>
      </div>

      <div className="flex-1 overflow-auto p-2">
        {/* 워크스페이, 공유 블럭 */}
        <nav className="grid gap-1">
          <Button
            className="w-full justify-between gap-2 rounded-lg bg-white shadow-[0_2px_4px_-1px_rgba(0,0,0,0.1)] hover:bg-gray-50"
            variant="ghost"
            onClick={() => setIsWorkspaceOpen(!isWorkspaceOpen)}
          >
            <div className="flex items-center gap-2">
              <Box className="size-4 text-gray-600" />
              My Workspace
            </div>
            <ChevronDown
              className={`size-4 text-gray-400 transition-transform duration-200 ${
                isWorkspaceOpen ? 'rotate-180' : ''
              }`}
            />
          </Button>

          {/* 드롭다운 메뉴 */}
          <div
            className={`grid gap-1 overflow-hidden transition-all duration-200 ${
              isWorkspaceOpen ? 'grid-rows-[1fr]' : 'grid-rows-[0fr]'
            }`}
          >
            <div className="min-h-0">
              <Link to="/dashboard/workspace/network" className="w-full">
                <Button
                  className="w-full justify-start gap-2 pl-9 text-gray-600 hover:bg-white"
                  variant="ghost"
                >
                  <Network className="size-4" />
                  Network
                </Button>
              </Link>
              <Link to="/dashboard/workspace/instance" className="w-full">
                <Button
                  className="w-full justify-start gap-2 pl-9 text-gray-600 hover:bg-white"
                  variant="ghost"
                >
                  <Server className="size-4" />
                  Instance
                </Button>
              </Link>
            </div>
          </div>
        </nav>

        {/* Get Started 섹션 */}
        {showGetStarted && (
          <div className="mt-6 rounded-lg border border-gray-100 p-4 shadow-[0_0_15px_rgba(0,0,0,0.15)]">
            <div
              className="mb-2 flex cursor-pointer items-center justify-between"
              onClick={toggleCollapse}
            >
              <span className="text-sm font-medium text-gray-700">
                Get Started
              </span>
              <div className="flex items-center gap-1 text-sm">
                <span className="text-gray-600">
                  {tasks.filter((task) => task.completed).length}/{tasks.length}
                </span>
                <ChevronDown
                  className={`size-4 text-gray-400 transition-transform duration-200 ${
                    isCollapsed ? 'rotate-90' : ''
                  }`}
                />
              </div>
            </div>

            {/* Progress bars */}
            <div className="mt-2 flex space-x-1">
              {tasks.map((item, index) => (
                <div
                  key={index}
                  className={`h-1.5 w-full rounded-full ${
                    item.completed ? 'bg-blue-600' : 'bg-gray-200'
                  }`}
                />
              ))}
            </div>

            {/* 온보딩 */}
            {!isCollapsed && (
              <>
                <Progress value={33} className="h-1" />
                <div className="mt-2 rounded-lg border bg-white p-4 shadow-md">
                  <div className="space-y-4">
                    <h2 className="text-xl font-semibold">
                      Hi,
                      {user.provider == 'google' && ` ${user.firstName} `}
                      {user.provider == 'github' && ` ${user.userName} `}
                      {user.provider == 'local' && ` ${user.userName} `} 👋
                    </h2>
                    <p className="text-sm text-gray-500">
                      Shall we get started?
                    </p>
                  </div>
                  <div className="mt-4 space-y-4">
                    <div className="space-y-2">
                      {tasks.map((item, index) => (
                        <div
                          className={`flex items-center gap-3 ${
                            index < tasks.length - 1
                              ? 'mb-2 border-b border-gray-200 pb-2'
                              : ''
                          }`}
                          key={index}
                        >
                          <div
                            className={`flex size-5 items-center justify-center rounded-full border ${
                              item.completed
                                ? 'border-blue-600 bg-blue-600'
                                : 'border-gray-200'
                            }`}
                          >
                            {item.completed && (
                              <svg
                                className="size-3 text-white"
                                fill="none"
                                strokeWidth="2"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                              >
                                <path d="M5 13l4 4L19 7" />
                              </svg>
                            )}
                          </div>
                          {[
                            'Configure Inline Policy',
                            'Copy & Paste Role Arn',
                            'Simulate policy',
                            'Deploy a VPN Server',
                          ].includes(item.text) ? (
                            item.completed ? (
                              <span className="text-sm text-gray-400 line-through">
                                {item.text}
                              </span>
                            ) : (
                              <Link
                                className="text-sm text-gray-700"
                                to={
                                  item.text === 'Deploy a VPN Server'
                                    ? '/dashboard/workspace/instance'
                                    : '/settings/crossaccount'
                                }
                              >
                                {item.text}
                              </Link>
                            )
                          ) : (
                            <span
                              className={`text-sm ${
                                item.completed
                                  ? 'text-gray-400 line-through'
                                  : ''
                              }`}
                            >
                              {item.text}
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                    <div className="mt-4 flex justify-center border-t border-gray-100 pt-4">
                      <Button
                        className="rounded-full border-none bg-gradient-to-r from-blue-500 to-indigo-500 px-6 text-sm font-medium text-white shadow-md transition-all duration-200 hover:opacity-90 hover:shadow-lg"
                        onClick={() => setShowGetStarted(false)}
                        variant="outline"
                        size="sm"
                      >
                        Skip Onboarding
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {/* 프로필바 */}
      <div className="menu-container relative border-t border-gray-100 p-2 shadow-[0_-2px_4px_-1px_rgba(0,0,0,0.1)]">
        <div
          className="flex cursor-pointer items-center justify-between rounded-lg p-2 hover:bg-white"
          onClick={toggleMenu}
        >
          <div className="flex items-center gap-2">
            <Avatar className="size-8 border-2 border-blue-600">
              <AvatarFallback>
                <User className="size-4" />
              </AvatarFallback>
            </Avatar>
            <div className="flex flex-col">
              {user.provider == 'google' && (
                <span className="text-sm font-medium text-gray-700">
                  {user.firstName} {user.lastName}
                </span>
              )}
              {(user.provider == 'github' || user.provider == 'local') && (
                <span className="text-sm font-medium text-gray-700">
                  {user.userName}
                </span>
              )}
            </div>
          </div>
          <span className="rounded-full bg-gray-900 px-2 py-1 text-xs text-white">
            Free
          </span>
        </div>

        {/* 프로필 메뉴 팝업 */}
        {isMenuOpen && (
          <div className="absolute inset-x-2 bottom-full mb-2 rounded-lg border border-gray-100 bg-white p-3 shadow-lg">
            <div className="flex flex-col">
              <span className="text-sm">
                {user.provider == 'google' &&
                  `${user.firstName} ${user.lastName}`}
                {user.provider == 'github' && `${user.userName}`}
                {user.provider == 'local' && `${user.userName}`}
              </span>
              <span className="text-xs text-gray-500">{user.email}</span>
              <div className="mt-2 border-t border-gray-200 pt-2">
                <div className="rounded-lg hover:bg-gray-100">
                  <div className="flex items-center gap-2 px-2">
                    <SettingsIcon className="size-4 text-gray-500" />
                    <Link to="/settings" className="w-full">
                      <Button
                        className="w-full justify-start px-0 font-medium text-gray-700 hover:bg-transparent hover:text-gray-900"
                        variant="ghost"
                      >
                        Settings
                      </Button>
                    </Link>
                  </div>
                </div>
                <div className="rounded-lg hover:bg-gray-100">
                  <div className="flex items-center gap-2 px-2">
                    <LogOut className="size-4 text-gray-500" />
                    <Button
                      className="w-full justify-start px-0 font-medium text-gray-700 hover:bg-transparent hover:text-gray-900"
                      variant="ghost"
                      onClick={handleLogout}
                    >
                      Sign out
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}
