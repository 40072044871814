import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const QUOTES = [
  'Oops! Our server is taking an unexpected coffee break.',
  'Houston, we have a problem... on our server.',
  'Looks like our hamster-powered server needs a new hamster.',
  'Our server decided to go on vacation without telling us.',
  "Error 500: Server's having an existential crisis.",
] as const;

export default function InternalServerError() {
  const [quote, setQuote] = useState(
    'Oops! Our server is taking an unexpected coffee break.',
  );
  const [searchParams] = useSearchParams();
  const errorMessage = searchParams.get('message');

  useEffect(() => {
    const interval = setInterval(() => {
      setQuote(QUOTES[Math.floor(Math.random() * QUOTES.length)]);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-black">
      <div className="w-full max-w-2xl px-4 text-center">
        <h1 className="mb-2 text-[150px] font-bold leading-none text-white opacity-20">
          500
        </h1>
        <p className="mb-2 text-2xl text-white">Internal Server Error</p>
        <p className="mb-4 text-lg font-semibold italic text-white">
          {errorMessage}
        </p>
        <p className="mb-8 truncate text-xl text-gray-400">{quote}</p>
        <button
          onClick={() => window.location.reload()}
          className="rounded-full bg-white px-6 py-3 font-semibold text-black transition-colors hover:bg-gray-200"
          aria-label="Try turning it off and on again"
        >
          Try turning it off and on again
        </button>
      </div>
    </div>
  );
}
