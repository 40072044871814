import { ErrorBoundary } from 'react-error-boundary';
import { useRouteError, isRouteErrorResponse } from 'react-router-dom';
import BadRequest from '@/pages/ErrorPage/BadRequest';
import InternalServerError from '@/pages/ErrorPage/InternalServerError';
import NotFound from '@/pages/ErrorPage/NotFound';
import Unauthorized from '@/pages/ErrorPage/Unauthorized';

interface ErrorResponse {
  status?: number;
  message?: string;
  data?: unknown;
}

interface FallbackProps {
  error: Error | null;
}

function ErrorFallback({ error }: FallbackProps) {
  // ErrorBoundary에서 잡은 일반적인 에러
  if (!error || !isRouteErrorResponse(error)) {
    return <NotFound />;
  }

  // Router에서 발생한 에러
  const routeError = error as ErrorResponse;

  if (routeError.status === 401) {
    return <Unauthorized />;
  }

  if (routeError.status === 400) {
    return <BadRequest />;
  }

  if (routeError.status === 500) {
    return <InternalServerError />;
  }

  return <NotFound />;
}

export default function ErrorPage() {
  const routeError = useRouteError();

  const handleError = (error: Error) => {
    console.error('Error:', error);
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={handleError}
      onReset={() => window.location.reload()}
    >
      <ErrorFallback error={routeError} />
    </ErrorBoundary>
  );
}
