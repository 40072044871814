import { client } from '@/api/api.client';
import { LoginPayload, SignupPayload, VerifyEmailPayload } from './auth.types';

export const AuthActions = {
  login: (payload: LoginPayload) =>
    client
      .post('/api/v1/user/login', payload)
      .then((response) => response.data),

  signup: (payload: SignupPayload) =>
    client
      .post('/api/v1/user/signup', payload)
      .then((response) => response.data),

  deleteAccount: () =>
    client.delete('/api/v1/user/me').then((response) => response.data),

  getUser: () =>
    client.post('/api/v1/user/me').then((response) => response.data),

  logout: () =>
    client.post('/api/v1/user/logout').then((response) => response.data),

  getTokenType: () =>
    client.post('/api/v1/user/token-type').then((response) => response.data),

  verifyEmail: (payload: VerifyEmailPayload) =>
    client
      .post('/api/v1/user/email/verification', payload)
      .then((response) => response.data),

  resendVerification: () =>
    client.put('/api/v1/user/email').then((response) => response.data),
};
