import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Instance } from './workspace.slice.types';

interface LogState {
  messages: string[];
  lastUpdated: string | null;
}

interface InstanceState {
  instances: Instance[];
  logs: {
    [containerId: string]: LogState;
  };
}

const initialState: InstanceState = {
  instances: [],
  logs: {},
};

export const instanceSlice = createSlice({
  name: 'instance',
  initialState,
  reducers: {
    setInstances: (state, action: PayloadAction<Instance[]>) => {
      state.instances = action.payload;
    },
    addInstance: (state, action: PayloadAction<Instance>) => {
      // instances가 undefined일 경우 빈 배열로 초기화
      if (!state.instances) {
        state.instances = [];
      }
      // 중복 체크 후 추가
      if (
        !state.instances.find((instance) => instance.id === action.payload.id)
      ) {
        state.instances.push(action.payload);
      }
    },
    removeInstance: (state, action: PayloadAction<string>) => {
      state.instances = state.instances.filter(
        (inst) => inst.id !== action.payload,
      );
    },
    setLog: (
      state,
      action: PayloadAction<{ containerId: string; log: string }>,
    ) => {
      const { containerId, log } = action.payload;

      // logs 객체가 undefined인 경우 초기화
      if (!state.logs) {
        state.logs = {};
      }

      // containerId에 대한 로그 배열이 없는 경우 초기화
      if (!state.logs[containerId]) {
        state.logs[containerId] = {
          messages: [],
          lastUpdated: null,
        };
      }

      // 로그 메시지 추가
      state.logs[containerId].messages.push(log);
      state.logs[containerId].lastUpdated = new Date().toISOString();
    },
    clearLogs: (state, action: PayloadAction<string>) => {
      const containerId = action.payload;

      // logs 객체가 undefined인 경우 초기화
      if (!state.logs) {
        state.logs = {};
      }

      // 해당 containerId의 로그 초기화
      state.logs[containerId] = {
        messages: [],
        lastUpdated: null,
      };
    },
    updateInstance: (state, action: PayloadAction<Instance>) => {
      const index = state.instances.findIndex(
        (instance) => instance.id === action.payload.id,
      );
      if (index !== -1) {
        state.instances[index] = action.payload;
      }
    },
  },
});

export const {
  setInstances,
  addInstance,
  updateInstance,
  removeInstance,
  setLog,
  clearLogs,
} = instanceSlice.actions;

export default instanceSlice.reducer;
