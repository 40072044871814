import { Link } from 'react-router-dom';
import Logo from '@/assets/logo.svg';
import { Button } from '@/components/ui/button';
import { useLogin } from '@/features/auth/hooks/useLogin';
import { useAppSelector } from '@/store';

export default function Header() {
  const { logout } = useLogin();
  const { tokenType } = useAppSelector((state) => state.auth);
  const isAuthenticated =
    tokenType === 'client_token' || tokenType === 'refresh_token';

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <nav className="fixed z-50 w-full border-b border-transparent bg-white/50 backdrop-blur-sm">
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
        <div className="flex h-16 items-center justify-between">
          {/* 로고 */}
          <Link to="/">
            <img src={Logo} alt="CircleSync Logo" className="h-10" />
          </Link>

          <div className="flex items-center gap-12">
            <div className="flex items-center gap-4">
              {/* Abount */}
              <Link to="/get-started">
                <Button className="hover:bg-gray-200" variant="ghost">
                  Getting Started
                </Button>
              </Link>

              {/* Pricing */}
              <Link to="/pricing">
                <Button className="hover:bg-gray-200" variant="ghost">
                  Pricing
                </Button>
              </Link>
            </div>

            {/* Log in과 Get Started 버튼 */}
            <div className="flex items-center gap-6">
              {isAuthenticated ? (
                <>
                  <Button
                    variant="ghost"
                    className="hover:bg-gray-200"
                    onClick={handleLogout} // 로그아웃 핸들러 추가 필요
                  >
                    Log out
                  </Button>
                  <Link to="dashboard/workspace">
                    <Button className="bg-blue-600 text-white hover:bg-blue-500">
                      Dashboard
                    </Button>
                  </Link>
                </>
              ) : (
                <>
                  <Link to="login">
                    <Button className="hover:bg-gray-200" variant="ghost">
                      Log in
                    </Button>
                  </Link>
                  <Link to="signup">
                    <Button className="bg-blue-600 text-white hover:bg-blue-500">
                      Get Started for free
                    </Button>
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
