import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import { PersistGate } from 'redux-persist/integration/react';
import queryClient from '@/config/queryClient';
import { router } from '@/routes';
import { store, persistor } from '@/store';

const container = document.getElementById('root') as HTMLDivElement;
const root = createRoot(container);

// Google Analytics 4 Settings
const gaTrackingId = import.meta.env.VITE_REACT_APP_GA_TRACKING_ID;
if (gaTrackingId) {
  ReactGA.initialize(gaTrackingId);
  router.subscribe((location) => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  });
}

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
);
