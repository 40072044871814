import { AxiosRequestConfig } from 'axios';
import CryptoJS from 'crypto-js';

const ENV = import.meta.env.MODE;
export const VERSION = 'v0';
export const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
export const SIGNING_SECRET = import.meta.env.VITE_SIGNING_SECRET;
export const CIRCLESYNC_ACCOUNT_ID = import.meta.env.VITE_CIRCLESYNC_ACCOUNT_ID;

// 환경 확인 헬퍼 함수 추가
export const isDevelopment = () => ENV === 'development';
export const isProduction = () => ENV === 'production';

// 서명 생성
export const generateSignature = (
  version: string,
  timestamp: string,
  requestBody: string,
) => {
  if (!SIGNING_SECRET) {
    throw new Error('Signing secret is not defined');
  }
  const calculated = `${version}:${timestamp}:${requestBody}`;
  const hmac = CryptoJS.HmacSHA256(calculated, SIGNING_SECRET);
  return `${version}=${hmac.toString(CryptoJS.enc.Hex)}`;
};

// 요청 헤더 생성
export const createHeaders = (requestBody = '') => {
  const timestamp = Math.floor(Date.now() / 1000).toString();
  const headers: Record<string, string> = {
    'X-PersonalConnect-Request-Timestamp': timestamp,
    'X-PersonalConnect-Signature': generateSignature(
      VERSION,
      timestamp,
      requestBody,
    ),
  };
  return headers;
};

// API 요청에 헤더 추가
export const addRequestHeaders = (config: AxiosRequestConfig) => {
  let requestBody = '';
  if (config.data) {
    requestBody =
      typeof config.data === 'string'
        ? config.data
        : JSON.stringify(config.data);
  }

  const headers = createHeaders(requestBody);
  Object.entries(headers).forEach(([key, value]) => {
    config.headers.set(key, value);
  });

  config.withCredentials = true;
  return config;
};

export interface Region {
  code: string;
  name: string;
  label: string;
}

export const regions: Region[] = [
  // Asia Pacific
  { code: 'ap-south-1', name: 'Mumbai' },
  { code: 'ap-south-2', name: 'Hyderabad' },
  { code: 'ap-northeast-1', name: 'Tokyo' },
  { code: 'ap-northeast-2', name: 'Seoul' },
  { code: 'ap-northeast-3', name: 'Osaka' },
  { code: 'ap-southeast-1', name: 'Singapore' },
  { code: 'ap-southeast-2', name: 'Sydney' },
  { code: 'ap-southeast-3', name: 'Jakarta' },
  { code: 'ap-southeast-4', name: 'Melbourne' },
  { code: 'ap-southeast-5', name: 'Malaysia' },
  { code: 'ap-east-1', name: 'Hong Kong' },

  // Americas
  { code: 'us-east-1', name: 'N. Virginia' },
  { code: 'us-east-2', name: 'Ohio' },
  { code: 'us-west-1', name: 'California' },
  { code: 'us-west-2', name: 'Oregon' },
  { code: 'ca-central-1', name: 'Central Canada' },
  { code: 'ca-west-1', name: 'Calgary' },
  { code: 'sa-east-1', name: 'São Paulo' },

  // Europe
  { code: 'eu-central-1', name: 'Frankfurt' },
  { code: 'eu-central-2', name: 'Zurich' },
  { code: 'eu-west-1', name: 'Ireland' },
  { code: 'eu-west-2', name: 'London' },
  { code: 'eu-west-3', name: 'Paris' },
  { code: 'eu-south-1', name: 'Milan' },
  { code: 'eu-south-2', name: 'Spain' },
  { code: 'eu-north-1', name: 'Stockholm' },

  // Middle East
  { code: 'me-central-1', name: 'UAE' },
  { code: 'me-south-1', name: 'Bahrain' },
  { code: 'il-central-1', name: 'Tel Aviv' },

  // Africa
  { code: 'af-south-1', name: 'Cape Town' },
];

// getRegionLabel 함수는 이제 regions 배열을 사용하도록 수정
export const getRegionLabel = (region: string): string => {
  const foundRegion = regions.find((r) => r.code === region);
  return foundRegion ? foundRegion.name : region;
};
