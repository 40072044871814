import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import LoadingSpinner from '@/components/ui/loding-spinner';
import Sidebar from '@/layouts/Sidebar';

export default function SidebarLayout() {
  return (
    <div className="flex min-h-screen overflow-x-hidden">
      <Sidebar className="fixed left-0 top-0 h-full w-[300px]" />
      <main className="flex-1 pl-[300px]">
        <Suspense
          fallback={
            <div className="flex h-screen w-full items-center justify-center">
              <LoadingSpinner />
            </div>
          }
        >
          <Outlet />
        </Suspense>
      </main>
    </div>
  );
}
