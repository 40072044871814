import { useSearchParams } from 'react-router-dom';

export default function BadRequest() {
  const [searchParams] = useSearchParams();
  const errorMessage = searchParams.get('message');

  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-orange-400">
      <div className="text-center">
        <h1 className="mb-2 text-[120px] font-bold leading-none text-white">
          400
        </h1>
        <p className="mb-2 text-2xl text-white">You've sent a bad request.</p>
        <p className="mb-4 text-lg font-semibold italic text-black">
          {errorMessage}
        </p>
        <button
          className="rounded-full bg-white px-6 py-3 font-semibold text-orange-400 transition-colors hover:bg-orange-100"
          onClick={() => (window.location.href = '/')}
        >
          Go to Homepage
        </button>
      </div>
    </div>
  );
}
