export default function Footer() {
  return (
    <footer className="mt-0 border-t bg-white/50 backdrop-blur-sm">
      <div className="mx-auto max-w-screen-xl px-4 py-8">
        <div className="text-center text-gray-600">
          © 2024 Personal Connect. All rights reserved.
        </div>
        <div className="mt-4 flex justify-center space-x-4 text-sm text-gray-400">
          <a href="/terms" className="hover:text-gray-600">
            Terms
          </a>
          <span>·</span>
          <a href="/privacy" className="hover:text-gray-600">
            Privacy
          </a>
        </div>
        <div className="mt-4 flex items-center justify-center space-x-6">
          <a
            href="https://github.com/personalconnect"
            className="text-gray-400 hover:text-gray-600"
          >
            <svg
              className="size-6"
              fill="currentColor"
              viewBox="0 0 24 24"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                clipRule="evenodd"
              />
            </svg>
          </a>
          <a
            href="mailto:albert@personalconnect.io"
            className="text-gray-400 hover:text-gray-600"
          >
            <svg
              className="size-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              aria-hidden="true"
            >
              <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
              <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
            </svg>
          </a>
        </div>
        <div className="mt-4 text-center text-sm text-gray-400">
          Outline is a proprietary brand of Jigsaw.
        </div>
        <div className="mt-1 text-center text-sm text-gray-400">
          CircleSync is not affiliated with Outline VPN in any way.
        </div>
      </div>
    </footer>
  );
}
