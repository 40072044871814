import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { getTokenType, refreshToken } from '@/api/user';
import { API_BASE_URL, addRequestHeaders } from '@/config';

// 클라이언트 생성
export const client: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus: (status) => status >= 200 && status < 300,
});

// 요청 인터셉터 설정 (API 요청 시 헤더 추가)
client.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    return addRequestHeaders(config);
  },
  (error) => {
    return Promise.reject(error);
  },
);

// 응답 인터셉터 설정
client.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // 401 에러이고 재시도하지 않은 요청인 경우에만 진행
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // 현재 토큰 타입 확인
        const { data: tokenType } = await getTokenType();

        if (tokenType === 'refresh_token') {
          try {
            // 토큰 갱신 요청 - 서버가 자동으로 쿠키를 설정함
            await refreshToken();
            return client(originalRequest);
          } catch (refreshError) {
            window.location.href = '/login';
            return Promise.reject(refreshError);
          }
        }
      } catch (tokenTypeError) {
        window.location.href = '/login';
        return Promise.reject(tokenTypeError);
      }
    }

    return Promise.reject(error);
  },
);

export default client;
