import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AuthState } from './auth.slice.types';

const initialState: AuthState = {
  tokenType: null,
  email: null,
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState: (
      state,
      action: PayloadAction<{ tokenType: string; email: string }>,
    ) => {
      state.tokenType = action.payload.tokenType;
      state.email = action.payload.email;
    },
    updateAuthState: (
      state,
      action: PayloadAction<{ tokenType: string; email: string }>,
    ) => {
      state.tokenType = action.payload.tokenType;
    },
    resetAuth: (state) => {
      state.tokenType = null;
      state.email = null;
    },
  },
});

export const { setAuthState, updateAuthState, resetAuth } = AuthSlice.actions;
export default AuthSlice.reducer;
