import loadingSpinner from '@/assets/loading.svg';

export default function LoadingSpinner() {
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="relative flex flex-col items-center gap-2">
        <div className="absolute inset-0 animate-[spin_3s_linear_infinite] rounded-full border-2 border-blue-500/30 border-t-blue-500" />
        <div className="absolute inset-0 animate-pulse rounded-full border-2 border-blue-200/50" />
        <img
          src={loadingSpinner}
          alt="loading"
          className="relative size-12 animate-spin"
        />
      </div>
    </div>
  );
}
