import { useQuery } from '@tanstack/react-query';
import { UserResponse } from '@circlesync/shared';
import { client } from '@/api/api.client';
import { useAppSelector } from '@/store';

export const useUser = () => {
  const { tokenType } = useAppSelector((state) => state.auth);

  const { data, isLoading } = useQuery<UserResponse>({
    queryKey: ['userInfo'],
    queryFn: () => client.post('/api/v1/user/me'),
    enabled: tokenType === 'client_token',
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 30,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    select: (response) => response.data,
  });

  return {
    user: data?.data,
    isLoading,
  };
};
