import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { TaskState } from './task.types';

const initialState: TaskState = {
  tasks: [
    { text: 'Configure Inline Policy', completed: false },
    { text: 'Copy & Paste Role Arn', completed: false },
    { text: 'Simulate policy', completed: false },
    { text: 'Deploy a VPN Server', completed: false },
  ],
};

export const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    toggleTask: (state, action: PayloadAction<number>) => {
      state.tasks[action.payload].completed =
        !state.tasks[action.payload].completed;
    },
    setTaskCompleted: (state, action: PayloadAction<string>) => {
      const task = state.tasks.find((task) => task.text === action.payload);
      if (task) {
        task.completed = true;
      }
    },
  },
});

export const { toggleTask, setTaskCompleted } = taskSlice.actions;
export default taskSlice.reducer;
