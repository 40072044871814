import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import authReducer from '@/features/auth/store/auth.slice';
import taskReducer from '@/features/task/store/task.slice';
import instanceReducer from '@/features/workspace/store/workspace.instance.slice';
import networkReducer from '@/features/workspace/store/workspace.network.slice';
import regionReducer from '@/features/workspace/store/workspace.region.slice';

// 로컬 스토리지 설정
const persistConfig = {
  key: 'circleSync',
  version: 1,
  storage,
  whitelist: ['auth', 'task', 'region', 'network'],
};

const instancePersistConfig = {
  key: 'instance',
  storage: sessionStorage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  task: taskReducer,
  instance: persistReducer(instancePersistConfig, instanceReducer),
  region: regionReducer,
  network: networkReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
