import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkState } from './workspace.slice.types';

const initialState: NetworkState = {
  creatingNetworks: {},
  destroyingNetworks: {},
};

const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setCreatingNetwork: (
      state,
      action: PayloadAction<{ region: string; isCreating: boolean }>,
    ) => {
      state.creatingNetworks[action.payload.region] = action.payload.isCreating;
    },
    setDestroyingNetwork: (
      state,
      action: PayloadAction<{ region: string; isDestroying: boolean }>,
    ) => {
      state.destroyingNetworks[action.payload.region] =
        action.payload.isDestroying;
    },
  },
});

export const { setCreatingNetwork, setDestroyingNetwork } =
  networkSlice.actions;
export default networkSlice.reducer;
