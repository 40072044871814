import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RegionState {
  selectedRegion: string;
}

const initialState: RegionState = {
  selectedRegion: '',
};

export const regionSlice = createSlice({
  name: 'region',
  initialState,
  reducers: {
    setSelectedRegion: (state, action: PayloadAction<string>) => {
      state.selectedRegion = action.payload;
    },
  },
});

export const { setSelectedRegion } = regionSlice.actions;
export default regionSlice.reducer;
