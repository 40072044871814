import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet } from 'react-router-dom';
import { LoadingSpinner } from '@/components/ui/spinner';
import { useUser } from '@/hooks/useUser';
import InternalServerError from '@/pages/ErrorPage/InternalServerError';
import { useAppSelector } from '@/store';

export const ProtectedRoute = () => {
  return (
    <ErrorBoundary
      FallbackComponent={({ error }) => {
        if (error.message === 'Unauthorized') {
          return <Navigate to="/login" replace />;
        }
        return <InternalServerError />;
      }}
    >
      <Suspense fallback={<LoadingSpinner />}>
        <AuthCheck />
      </Suspense>
    </ErrorBoundary>
  );
};

const AuthCheck = () => {
  const { tokenType } = useAppSelector((state) => state.auth);
  const persistComplete = useAppSelector((state) => state._persist?.rehydrated);
  const { user, isLoading } = useUser();

  if (!persistComplete || isLoading) {
    return <LoadingSpinner />;
  }

  if (tokenType !== 'client_token' || !user) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};
