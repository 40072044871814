export default function NotFound() {
  return (
    <div className="flex min-h-screen flex-col items-center justify-center bg-black">
      <div className="text-center">
        <h1 className="mb-2 text-[120px] font-bold leading-none text-white">
          404
        </h1>
        <p className="mb-8 text-2xl text-white">Page not found.</p>
        <button
          className="rounded-full bg-white px-6 py-3 font-semibold text-black transition-colors hover:bg-gray-200"
          aria-label="Go to homepage"
          onClick={() => (window.location.href = '/')}
        >
          Go to Homepage
        </button>
      </div>
    </div>
  );
}
